import React from 'react';
import styles from './index.module.scss';
import { FAQCategory } from '~/common/models';
import { AccordionItem } from '~/common/components/ui-elements';

interface FAQProp {
  id: number;
  title?: string;
  paragraphText?: string;
  faqCategories: FAQCategory[];
  textAlignment: 'left' | 'center' | 'right';
}

export default (props: FAQProp) => {
  const _id = `cid-${props.id}`;
  return (
    <div id={_id} className={styles.faqMain}>
      <div className={styles.faqWrapper}>
        {props.title && (
          <div
            className={styles.faqTitle}
            style={{ textAlign: props.textAlignment }}
            dangerouslySetInnerHTML={{ __html: props.title }}
          />
        )}
        {props.paragraphText && (
          <div
            className={styles.faqParagraph}
            style={{ textAlign: props.textAlignment }}
            dangerouslySetInnerHTML={{ __html: props.paragraphText }}
          />
        )}
        {props.faqCategories && (
          <div className={styles.faqCategories}>
            {props.faqCategories.map((category, index) => (
              <div key={index}>
                {category.title && (
                  <div
                    className={styles.categoryTitle}
                    dangerouslySetInnerHTML={{ __html: category.title }}
                  />
                )}
                <div className={styles.categoryFaqs}>
                  {category.faqs.map((faq, i) => (
                    <AccordionItem
                      key={i}
                      title={faq.question}
                      content={faq.answer}
                      contentClass={styles.faqAnswer}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
